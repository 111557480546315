let config = {
    s3: {
        REGION: "us-east-1",
        BUCKET: "forwood-rossing-prod-forwoodid",
        BATCH_PROCESS_BUCKET_NAME: "forwood-rossing-prod-forwoodid-batch-user-process"
    },
    appSyncAPI: {
        REGION: "us-east-1",
        URL: "https://tn6vvcwvondmhdrr4lam3wtllu.appsync-api.us-east-1.amazonaws.com/graphql",
    },
    apiGateway: {
        REGION: "us-east-1",
        URL: "https://c6em7kc52a.execute-api.us-east-1.amazonaws.com/env",
        USER_TOKEN_URL: "https://5hv8zdudwj.execute-api.us-east-1.amazonaws.com/prod"
    },
    configurationService: {
        URL: "https://config.rossing.forwoodsafety.com",
        WEBSOCKET: "wss://407rcjdf6d.execute-api.us-east-1.amazonaws.com/prod"
    },
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_iprmNd3EW",
        APP_CLIENT_ID: "58r0jj0btpcn52oq96ttro0dfc",
        IDENTITY_POOL_ID: "us-east-1:fee367c0-e17a-4daf-995e-42dde3dec927",
        USERPOOL_HOSTED_DOMAIN: "forwood-rossing-id-prod",
        USERPOOL_ADFS_CALLBACK_URL: "https://id.rossing.forwoodsafety.com",
        USERPOOL_ADFS_LOGOUT_URL: "https://id.rossing.forwoodsafety.com",
        AUTHENTICATED_ROLE_ARN: "arn:aws:iam::216464760721:role/prod-CA",
        get USERPOOL_ADFS_LAUNCH_URL () {
          return 'https://'+this.USERPOOL_HOSTED_DOMAIN+'.auth.'+this.REGION+'.amazoncognito.com/authorize?redirect_uri='+this.USERPOOL_ADFS_CALLBACK_URL+'&response_type=code&client_id='+this.APP_CLIENT_ID;
        }
    },
    samlProvider: {
      NAME: 'Rossing',
      SUPPORTED_LIST: 'Rossing,ForwoodAzure'
    },
    reactApp: {
        VERSION: "1.34.3",
        HOSTNAME: "id.rossing.forwoodsafety.com",
        COOKIE_DOMAIN: ".rossing.forwoodsafety.com",
        SECURE_PROTOCOL: true,
        FORWOOD_COMPANY_ID: "434adf68-536a-41c0-b189-b1b4afd3765f",
        FORWOOD_HOMEPAGE_MICROFRONTEND_URL: "https://homepage.rossing.forwoodsafety.com"
    }
};

export default config;
